import { useLocalStorage } from "@vueuse/core";

const DEFAULT_VIEW_KEY = "ecia-search-view";
export type SearchView = "by-part" | "by-distributor";

export default function useSearchView() {
    const { gtag } = useGtag();
    const globalConfig = useStateGlobalConfig();
    const { t } = useI18n();
    const { toast } = useToast();

    const defaultView = useLocalStorage<SearchView>(
        DEFAULT_VIEW_KEY,
        globalConfig.value.Features.RESULTS_BY_PART_DEFAULT ? "by-part" : "by-distributor"
    );

    const isDefaultByPart = computed<boolean>(() => defaultView.value === "by-part");

    const isDefaultByDistributor = computed<boolean>(() => defaultView.value === "by-distributor");

    function setDefaultView(view: SearchView) {
        defaultView.value = view;
        const labelType = view === "by-distributor" ? "Distributor" : "Part";
        const description = t(`Search.By${labelType}DefaultViewSuccess`);
        toast({ description });
    }

    const currentView = ref<SearchView>("by-part");

    onMounted(() => {
        watch(defaultView, (view) => gtag("event", "results_view_set", { view }));
        watch(currentView, (view) => gtag("event", "results_view_change", { view }));
    });

    return { defaultView, currentView, isDefaultByPart, isDefaultByDistributor, setDefaultView };
}
