<template>
    <div class="overflow-x-auto">
        <table class="table table-search-results">
            <thead>
                <tr>
                    <th v-if="showDistributor" class="w-40" v-html="$t('Search.AuthorizedDistributor')"></th>
                    <template v-if="showManufacturer">
                        <th>{{ $t("Search.Part_Number") }}</th>
                        <th v-if="showManufacturerName">{{ $t("Search.Manufacturer") }}</th>
                        <th v-if="showDescription">{{ $t("Search.Description") }}</th>
                    </template>
                    <th v-if="showRohs">{{ $t("Search.RoHS") }}</th>
                    <th v-if="showDatasheet" class="w-24">{{ $t("Global.Datasheet") }}</th>
                    <th v-if="showDistributorPartNumber">{{ $t("Search.Supplier_Number") }}</th>
                    <th v-if="showPackaging">{{ $t("Search.PackagingHeader") }}</th>
                    <PartStockHeader />
                    <th>{{ $t("Search.Pricing") }}</th>
                    <th class="w-24 print:hidden"></th>
                </tr>
            </thead>
            <tbody>
                <SearchPartResult
                    v-for="part in results"
                    :key="part.SortOrder"
                    v-model:expanded="expanded[part.SortOrder]"
                    :show-child="expanded[part.ParentSortOrder]"
                    :duplicate-count="partDuplicateCounts[part.SortOrder]"
                    :part
                    :show-distributor
                    :show-distributor-logo
                    :show-manufacturer
                    :show-manufacturer-name
                    :show-description
                    :show-rohs
                    :show-datasheet
                    :show-distributor-part-number
                    :show-packaging />
            </tbody>
        </table>
    </div>
</template>
<script setup lang="ts">
type SearchPartResultsProps = {
    parts: PartResult[];
    showDistributor?: boolean;
    showManufacturer?: boolean;
    showRohs?: boolean;
    showDescription?: boolean;
};

const props = withDefaults(defineProps<SearchPartResultsProps>(), {
    showDistributor: false,
    showManufacturer: false,
    showRohs: false,
});

const expanded = ref<Record<string, boolean>>({});
const breakpoints = useBreakpoints();
const searchIds = inject(SearchIdsInjectKey);

const smAndLarger = breakpoints.greaterOrEqual("sm");
const mdAndLarger = breakpoints.greaterOrEqual("md");
const lgAndLarger = breakpoints.greaterOrEqual("lg");

const results = computed(() =>
    props.parts.slice().sort((a, b) => {
        return new String(a.SortOrder).localeCompare(b.SortOrder);
    })
);

const partDuplicateCounts = computed(() =>
    props.showDistributor ? getPartDuplicateCounts(results.value, searchIds?.sortOrders.value) : {}
);

const showDistributor = computed(() => props.showDistributor);
const showDistributorLogo = smAndLarger;
const showManufacturer = computed(() => props.showManufacturer);
const showManufacturerName = computed(() => props.showManufacturer && mdAndLarger.value);
const showDescription = computed(() => (!props.showDistributor || props.showDescription) && mdAndLarger.value);
const showRohs = computed(() => props.showRohs && lgAndLarger.value);
const showDatasheet = mdAndLarger;
const showDistributorPartNumber = lgAndLarger;
const showPackaging = lgAndLarger;
</script>
