<template>
    <button
        v-bind="forwardedProps"
        :class="
            cn(
                'flex space-x-2 font-bold items-center justify-center ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-accent py-2 px-4 first:border-t last:border-b border-x md:border-y md:first:border-l md:border-l-0 border-accent hover:bg-gray-100 max-md:first:rounded-t max-md:last:rounded-b-md md:first:rounded-l-md md:last:rounded-r-md data-[state=active]:text-white data-[state=active]:bg-accent',
                props.class
            )
        "
        role="tab"
        :aria-selected="isSelected ? 'true' : 'false'"
        :aria-controls="contentId"
        :data-state="isSelected ? 'active' : 'inactive'"
        @click="context?.setTab(props.value)">
        <span>
            <slot />
        </span>
    </button>
</template>
<script setup lang="ts">
import { type TabsTriggerProps, useForwardProps } from "radix-vue";
import { computed, type HTMLAttributes } from "vue";

const props = defineProps<TabsTriggerProps & { class?: HTMLAttributes["class"]; contentId: string }>();

const delegatedProps = computed(() => {
    const { class: _, contentId: _contentId, ...delegated } = props;

    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
const context = inject(SearchInjectKey);

const isSelected = computed(() => props.value === context?.tab.value);
</script>
