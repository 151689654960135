<template>
    <Popover>
        <PopoverTrigger as-child>
            <Button class="group" variant="primary">
                <span>{{ props.label }}</span>
                <Icon name="chevron-right" class="group-data-[state=open]:rotate-90 transition-transform" />
                <Badge v-if="firstCompany" variant="primary" @click.stop="remove(firstCompany.Id)">
                    <span>{{ firstCompany.DisplayName }}</span>
                    <Icon name="xmark" />
                </Badge>
                <Badge v-if="otherCompanies" variant="outline">+{{ otherCompanies }}</Badge>
            </Button>
        </PopoverTrigger>
        <PopoverContent align="end" class="p-1 min-w-96" @open-auto-focus.prevent>
            <Command v-model="selected" multiple :filter-function>
                <CommandInput />
                <div class="flex justify-end gap-1 font-bold text-right after:overflow-y-scroll after:invisible">
                    <span class="min-w-14">{{ $t("Search.Results") }}</span>
                    <span class="min-w-14">{{ $t("Search.Exact") }}</span>
                </div>
                <CommandList class="overflow-y-scroll">
                    <SearchFilterItem
                        v-for="company in props.companies"
                        :key="company.Id"
                        :value="company.Id"
                        :company />
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
</template>
<script setup lang="ts">
const props = defineProps<{ label: string; companies: SearchFilterCompany[]; showFirstCompany: boolean }>();
const selected = defineModel<number[] | undefined>({ required: true });

function remove(item: number) {
    if (selected.value) selected.value = selected.value.filter((i) => i !== item);
}

/* If the user has selected one company, then we'd like to display it inline with the filter, but only on large screens.
However, even then company names can be fairly long so we can only show the first company. */
const firstCompany = computed(() => {
    if (!props.showFirstCompany || !Array.isArray(selected.value)) return null;
    const [id] = selected.value;
    return id ? props.companies.find((co) => co.Id == id) : null;
});

/* Show the number of companies selected (minus one if we are already showing the first company) */
const otherCompanies = computed(() => {
    const offset = props.showFirstCompany ? 1 : 0;
    return Array.isArray(selected.value) && selected.value.length > offset ? selected.value.length - offset : 0;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function filterFunction(list: any, searchTerm: string) {
    if (!searchTerm) return list;
    const lowerTerm = searchTerm.toLocaleLowerCase();
    return props.companies.filter((co) => co.Name.toLocaleLowerCase().includes(lowerTerm)).map((co) => co.Id);
}
</script>
