<template>
    <div
        :id="props.id"
        :data-testid="props.id"
        role="tabpanel"
        :aria-labelledby="props.triggerId"
        :hidden="!props.selected"
        tabindex="0">
        <slot v-if="selected" />
    </div>
</template>
<script setup lang="ts">
const props = defineProps<{ id: string; triggerId: string; selected: boolean }>();
</script>
