<template>
    <a
        v-if="props.part.ProductUrl"
        :href="props.part.ProductUrl"
        data-ga="s:Part"
        target="_blank"
        rel="noopener nofollow"
        class="inline-block">
        <EnhancedImage v-if="props.showDistributorLogo" :css-class="imageClass" v-bind="imageProps" />
        <span v-else class="text-base">{{ props.part.Distributor.DisplayName }}</span>
    </a>
    <template v-else>
        <EnhancedImage v-if="props.showDistributorLogo" :css-class="imageClass + ' inline-block'" v-bind="imageProps" />
        <span v-else class="text-base">{{ props.part.Distributor.DisplayName }}</span>
    </template>
</template>
<script setup lang="ts">
const props = defineProps<{ part: PartResult; showDistributorLogo: boolean }>();

// synchronize with PartResultsRow.vue
const isSponsorLogo = computed(() => props.part.LogoCssClass.includes("Sponsor"));
const imageHeight = computed(() => (isSponsorLogo.value ? "40" : "36"));
const imageWidth = computed(() => (isSponsorLogo.value ? "94" : "86"));
const imageClass = computed(() => (isSponsorLogo.value ? "max-h-10 max-w-[94px]" : "max-h-9 max-w-[86px]"));

const imageProps = computed(() => {
    return {
        selfHosted: true,
        src: props.part.Distributor.LogoUrl,
        alt: props.part.Distributor.DisplayName,
        height: imageHeight.value,
        width: imageWidth.value,
    };
});
</script>
