<template>
    <div data-testid="search-filters" class="flex flex-wrap gap-2 md:justify-end">
        <SearchFilterInStock />
        <SearchFilterCompany
            v-if="showDistributors"
            v-model="filterValues.distributorIds"
            :label="$t('Search.FilterDistributors')"
            :companies="filterDistributors"
            :show-first-company="mdAndLarger" />
        <SearchFilterCompany
            v-if="showManufacturers"
            v-model="filterValues.manufacturerIds"
            :label="$t('Search.FilterManufacturers')"
            :companies="filterManufacturers"
            :show-first-company="mdAndLarger" />
    </div>
</template>
<script setup lang="ts">
const filterValues = useStateSearchFilters();

const props = defineProps<{
    // results: DistributorSearchResult[];
    partResults: PartResult[];
    distributors: PartResultDistributor[];
    manufacturers: PartResultManufacturer[];
}>();

const breakpoints = useBreakpoints();

const mdAndLarger = breakpoints.greaterOrEqual("md");

const isFilteringManufacturers = computed(() => filterValues.value.manufacturerIds.length > 0);
const isFilteringDistributors = computed(() => filterValues.value.distributorIds.length > 0);
const showDistributors = computed(() => props.distributors.length > 1 || isFilteringDistributors.value);
const showManufacturers = computed(() => props.manufacturers.length > 1 || isFilteringManufacturers.value);
const combinations = computed(() => calculateCompanyCombinations(props.partResults, filterValues.value.inStock));
const distributorIdSet = computed(() => new Set(filterValues.value.distributorIds));
const manufacturerIdSet = computed(() => new Set(filterValues.value.manufacturerIds));

const filterDistributors = computed<SearchFilterCompany[]>(() => {
    return filterCompanies(
        props.distributors,
        combinations.value.distributorManufacturers,
        distributorIdSet.value,
        manufacturerIdSet.value
    );
});

const filterManufacturers = computed<SearchFilterCompany[]>(() => {
    return filterCompanies(
        props.manufacturers,
        combinations.value.manufacturerDistributors,
        manufacturerIdSet.value,
        distributorIdSet.value
    );
});

/**
 * This function creates a list of SearchFilterCompany records for both computed properties above.
 * They are essentially the same, just mirror images of each other.
 * @param companies unfiltered list of companies
 * @param combos pre-aggregated count lookup table from calculateCompanyCombinations
 * @param companyIds set of filtered IDs for this type of company (same as companies param)
 * @param otherIds set of filtered IDs for the other type of company (not companies param)
 */
function filterCompanies(
    companies: Omit<SearchFilterCompany, "Total" | "Exact">[],
    combos: CompanyCombinations,
    companyIds: Set<number>,
    otherIds: Set<number>
): SearchFilterCompany[] {
    // if the other filter is not being used, then use the whole companies list and get totals
    if (otherIds.size < 1) return companies.map((company) => ({ ...company, ...combos.sumCounts(company.Id) }));

    // only return companies that are either selected or have valid combos, and then get totals
    return companies
        .filter((company) => companyIds.has(company.Id) || combos.has(company.Id, otherIds))
        .map((company) => ({ ...company, ...combos.sumCounts(company.Id, otherIds) }));
}
</script>
