<template>
    <tr :class="rowClass" v-bind="rowDataProps">
        <td
            v-if="props.showDistributor && !props.part.IsDistributorDuplicate"
            class="w-32 text-center"
            :rowspan="distributorRowspan">
            <SearchDistributorLogo :part :show-distributor-logo />
            <div v-if="props.duplicateCount" class="mt-1">
                <button class="text-xs py-1 px-4 link-blue whitespace-nowrap" @click="expanded = !expanded">
                    {{ toggleDuplicatesText }}
                </button>
            </div>
        </td>
        <template v-if="props.showManufacturer">
            <td class="font-bold">
                <div v-if="!props.showManufacturerName">
                    {{ props.part.Manufacturer.DisplayName }}
                </div>
                <a
                    v-if="props.part.ProductUrl"
                    :href="props.part.ProductUrl"
                    class="whitespace-nowrap"
                    data-ga="s:Part"
                    target="_blank"
                    rel="noopener nofollow">
                    {{ props.part.PartNumber }}
                </a>
                <span v-else class="whitespace-nowrap">{{ props.part.PartNumber }}</span>
            </td>
            <td v-if="props.showManufacturerName" class="font-bold">
                {{ props.part.Manufacturer.DisplayName }}
            </td>
            <td v-if="props.showDescription" class="text-xs">
                <span>{{ descriptionText }}</span>
                <Button v-if="showSeeMore" size="sm" variant="link" class="h-6 px-2" @click="seeFullDescription = true">
                    {{ $t("Search.SeeMore") }}
                </Button>
            </td>
        </template>
        <td v-if="props.showRohs" class="text-center text-xs">
            <div v-for="r in roHS" :key="r.index">{{ r.value }}</div>
        </td>
        <td v-if="props.showDatasheet" class="text-center">
            <a
                v-if="props.part.DatasheetUrl"
                :href="props.part.DatasheetUrl"
                data-ga="s:Datasheet"
                target="_blank"
                rel="noopener nofollow"
                class="inline-block"
                :title="$t('Global.Datasheet')">
                <img
                    :src="pdfIconUrl"
                    :alt="$t('Global.DatasheetImageAltText', [part.Manufacturer.Name, part.PartNumber])"
                    loading="lazy" />
            </a>
        </td>
        <td v-if="props.showDistributorPartNumber" class="text-center text-xs">{{ part.DistributorPartNumber }}</td>
        <td v-if="props.showPackaging" class="text-center text-xs">
            <div v-for="pkg in props.part.Packaging" :key="pkg.PackageType">
                {{ pkg.PackageType }}
                <span v-if="pkg.MinimumOrderQuantity">({{ pkg.MinimumOrderQuantity.toLocaleString() }})</span>
            </div>
        </td>
        <td class="relative min-w-24 overflow-hidden text-center !pb-6">
            <PartStockColumn :result="props.part" />
        </td>
        <td class="text-xs">
            <PartPriceBreaksColumn :prices="props.part.Prices" />
        </td>
        <td class="text-center">
            <template v-if="props.part.ProductUrl">
                <Button
                    as="a"
                    class="rounded-full uppercase min-w-20"
                    fill="solid"
                    target="_blank"
                    rel="noopener nofollow"
                    :data-ga="props.part.ShowBuyButton ? 's:Buy' : 's:View'"
                    :href="props.part.ProductUrl"
                    :variant="props.part.ShowBuyButton ? 'primary' : 'accent'">
                    {{ props.part.ShowBuyButton ? props.part.BuyText : props.part.ViewText }}
                </Button>
                <span class="inline-block p-2">{{ part.Currency }}</span>
            </template>
        </td>
    </tr>
</template>
<script setup lang="ts">
type SearchPartResultProps = {
    part: PartResult;
    showDistributor: boolean;
    showDistributorLogo: boolean;
    showManufacturer: boolean;
    showManufacturerName: boolean;
    showRohs: boolean;
    showDescription: boolean;
    showDatasheet: boolean;
    showDistributorPartNumber: boolean;
    showPackaging: boolean;
    showChild?: boolean;
    duplicateCount?: number;
};
const { t } = useI18n();
const config = useRuntimeConfig();
const cdnUrl = config.public.cdnUrl;
const pdfIconUrl = cdnUrl + "/assets/images/adobe_pdf_file_icon_24x24.png";

const { MAX_DESCRIPTION_LENGTH } = SEARCH;
const expanded = defineModel<boolean>("expanded");
const seeFullDescription = ref<boolean>(false);

const props = defineProps<SearchPartResultProps>();

const searchIds = inject(SearchIdsInjectKey);

/**
 * The part result may be hidden because either the part itself identified by the key is not visible,
 * or the specific part result as identified by the sort order is not visible.
 * See calculateVisibleIds in utils/search for more details.
 */
const isPartResultHidden = computed(
    () => !searchIds?.partKeys.value.has(props.part.Key) || !searchIds?.sortOrders.value.has(props.part.SortOrder)
);

const isRowHidden = computed(() => props.showDistributor && props.part.IsDistributorDuplicate && !props.showChild);

const rowClass = computed(() => {
    // duplicate and expanded classes are only for formatting collapsable rows with a distributor logo column
    return {
        hidden: isPartResultHidden.value || isRowHidden.value,
        duplicate: props.showDistributor && props.part.IsDistributorDuplicate,
        expanded: props.showDistributor && expanded.value,
    };
});

const rowDataProps = computed(() => {
    return {
        "data-dist": props.part.Distributor.Name + " [" + props.part.Distributor.Id + "]",
        "data-mfr": props.part.Manufacturer.Name + " [" + props.part.Manufacturer.Id + "]",
        "data-mpn": props.part.PartNumber,
        "data-cur": props.part.Currency,
        "data-stock-qty": props.part.QuantityOnHand,
        "data-sortorder": props.part.SortOrder,
        "data-parent-sortorder": props.part.ParentSortOrder,
        "data-is-distributor-duplicate": props.part.IsDistributorDuplicate,
        "data-hash": searchIds?.clickHash.value,
    };
});

const showSeeMore = computed(() => !seeFullDescription.value && props.part.Description.length > MAX_DESCRIPTION_LENGTH);

const descriptionText = computed(() => {
    const str = props.part.Description + "";

    if (seeFullDescription.value || str.length <= MAX_DESCRIPTION_LENGTH) return str;
    const index = str.lastIndexOf(" ", MAX_DESCRIPTION_LENGTH);
    return str.substring(0, index > 1 ? index : MAX_DESCRIPTION_LENGTH);
});

const roHS = computed(() => {
    if (!props.showRohs) return [];
    return (SearchHelpers.getRoHS(props.part.RoHS, t) ?? []).map((value, index) => ({ value, index }));
});

const toggleDuplicatesText = computed(() =>
    expanded.value ? t("Search.HideDistributorDuplicates") : t("Search.ShowDistributorDuplicates")
);

const distributorRowspan = computed(() => (expanded.value && props.duplicateCount ? props.duplicateCount + 1 : 1));
</script>
