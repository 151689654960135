<template>
    <div>
        <label v-if="!isOnDefaultView" class="inline-flex gap-2 align-middle cursor-pointer">
            <input type="checkbox" @click="setDefaultView(props.view)" />
            <span v-html="labelHtml"></span>
        </label>
    </div>
</template>
<script setup lang="ts">
const { t } = useI18n();
const { defaultView, setDefaultView } = useSearchView();
const props = defineProps<{ view: SearchView }>();

const isOnDefaultView = computed(() => props.view == defaultView.value);

const labelHtml = computed(() =>
    t(props.view == "by-part" ? "Search.MakeByPartDefaultView" : "Search.MakeByDistributorDefaultView")
);
</script>
