import { useBreakpoints as useBreakpointsVueuse } from "@vueuse/core";

// see https://vueuse.org/core/useBreakpoints/ for usage examples
export const useBreakpoints = () => {
    const breakpoints = useBreakpointsVueuse(
        // be sure to sync these sizes with screens in ~/tailwind.config
        {
            xs: 375,
            sm: 768,
            md: 992,
            lg: 1200,
            xl: 1400,
        },
        { ssrWidth: 1200 }
    );

    return breakpoints;
};
