<template>
    <div class="*:my-4">
        <SearchViewSwitch view="by-part" />
        <SearchPart v-for="part in resultsByPart" :key="part.Key" :part>
            <template v-if="charts[part.Key]" #chart>
                <div class="hidden lg:block w-1/4">
                    <KeepAlive>
                        <PartChartPreview
                            v-if="part.Manufacturer"
                            :part="part"
                            :part-key="part.Key"
                            :initial-chart-data="charts[part.Key]"
                            preview-height="100"
                            @click="partChartDialog?.open(part, part.Key, $event)" />
                    </KeepAlive>
                </div>
            </template>
            <template #actions>
                <PartAddToBom :part size="sm" />
                <NotificationButton
                    size="sm"
                    :disabled="isNotificationLoading"
                    :has-notification="hasNotifications[part.PartId ?? 0] ?? false"
                    @click="openNotification(part)" />
            </template>
        </SearchPart>
        <NotificationDialog ref="notificationDialog" />
        <PartChartDialog ref="partChartDialog" />
    </div>
</template>
<script setup lang="ts">
const props = defineProps<{ searchResults: SearchResultsTransformed }>();
const isAuthenticated = useStateIsAuthenticated();
const api = useApi();
const globalConfig = useStateGlobalConfig();

const charts = shallowRef<Record<string, PartChartData>>({});
const notifications = shallowRef<Record<string, PartNotification>>({});
const hasNotifications = shallowRef<Record<string, boolean>>({});
const isNotificationLoading = ref(false);
const notificationDialog = useTemplateRef("notificationDialog");
const partChartDialog = useTemplateRef("partChartDialog");
const searchText = globalConfig.value.SearchText;

const resultsByPart = computed(() => groupResultsByPart(searchText, props.searchResults));

function openNotification(part: CommonPart) {
    notificationDialog.value?.openDialog(part, notifications.value[part.PartId ?? 0]);
}

onMounted(() => {
    const fetchOptions = {
        body: {
            Parts: resultsByPart.value.map((item) => ({
                ManufacturerId: item.ManufacturerId,
                PartNumber: item.PartNumber,
            })),
        },
    };
    api<PartChartData[]>("api/part-chart", { method: "POST", ...fetchOptions }).then((data) => {
        if (Array.isArray(data)) charts.value = Object.fromEntries(data.map((item) => [item.Key, item]));
    });

    if (isAuthenticated.value) {
        isNotificationLoading.value = true;
        api<PartNotificationsResponse | null>("api/notification-data", { method: "POST", ...fetchOptions })
            .then((data) => {
                if (data) {
                    notifications.value = Object.fromEntries(data.PartNotifications.map((n) => [n.PartId, n]));
                    hasNotifications.value = Object.fromEntries(data.PartNotifications.map((n) => [n.PartId, true]));
                } else {
                    notifications.value = {};
                    hasNotifications.value = {};
                }
            })
            .finally(() => {
                isNotificationLoading.value = false;
            });
    }
});
</script>
