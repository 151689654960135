<template>
    <div>
        <SearchViewSwitch class="my-4" view="by-distributor" />
        <SearchDistributorPart v-if="mainPart" :result="mainPart" :class="{ hidden: isMainPartHidden }" />
        <template v-if="hasExactMatches">
            <div v-if="!mainPart" class="flex mb-2 justify-between">
                <h3>{{ exactMatchesHeader }}</h3>
                <div>({{ exactMatchesTotalLabel }})</div>
            </div>
            <SearchPartResults
                :parts="exactMatches"
                :class="{ hidden: isMainPartHidden }"
                show-distributor
                show-manufacturer
                show-description />
        </template>
        <h2 v-if="hasVisibleParts" class="h3 my-4">
            {{ $t("Search.HeaderAllMatches") }}
        </h2>
        <SearchDistributor
            v-for="distributor in resultsByDistributor"
            :id="'distributor-' + distributor.Id"
            :key="distributor.Id"
            class="my-4 scroll-mt-28 xl:scroll-mt-24"
            :distributor />
    </div>
</template>
<script setup lang="ts">
const { t } = useI18n();
const globalConfig = useStateGlobalConfig();
const props = defineProps<{ searchResults: SearchResultsTransformed }>();
const searchIds = inject(SearchIdsInjectKey);

const mainPart = computed(() => props.searchResults.MainPartDetailResult);

// the main part and exact matches will be hidden if it doesn't pass the filters
const isMainPartHidden = computed(() => mainPart.value && !searchIds?.partKeys.value.has(mainPart.value.Key));

const hasVisibleParts = computed(() => searchIds?.partKeys.value.size ?? 0 > 0);

const exactMatches = computed(() => props.searchResults.PartResults.filter((partResult) => partResult.IsExactMatch));

const totalExactMatches = computed(() => exactMatches.value.length);

const hasExactMatches = computed(
    () =>
        totalExactMatches.value > 0 &&
        exactMatches.value.some((partResult) => searchIds?.sortOrders.value.has(partResult.SortOrder))
);

const exactMatchesHeader = computed(() => t("Search.HeaderExactMatches", [globalConfig.value.SearchText]));

const exactMatchesTotalLabel = computed(() =>
    totalExactMatches.value > 1 ? t("Search.Matches", [totalExactMatches.value]) : t("Search.Match")
);

const resultsByDistributor = computed(() => groupResultsByDistributor(props.searchResults, import.meta.client));
</script>
