<template>
    <section>
        <PageHeader class="px-6 gap-y-2 flex-col md:flex-row">
            <h1 ref="header">
                {{ $t("Search.MessageSearching") }}
                {{ globalConfig.SearchText }}
            </h1>
            <div class="hidden md:block grow"></div>
            <SearchDistributorLinks
                v-if="searchContext && searchContext.IsValidSearch && currentView == 'by-distributor'"
                :distributors />
        </PageHeader>
        <div v-if="isSearchInvalid" class="px-6">
            <SearchInvalid :result="invalidSearchResult" />
        </div>
        <div v-else class="px-6 md:flex mt-4 gap-4">
            <div class="grow">
                <div class="flex flex-col md:flex-row justify-between items-center gap-4">
                    <SearchTabs v-model="currentView">
                        <SearchTabsTrigger id="search-tab-by-part" value="by-part" content-id="search-parts">
                            {{ $t("Search.ByPart") }}
                        </SearchTabsTrigger>
                        <SearchTabsTrigger
                            id="search-tab-by-distributor"
                            value="by-distributor"
                            content-id="search-distributors">
                            {{ $t("Search.ByDistributor") }}
                        </SearchTabsTrigger>
                    </SearchTabs>
                    <Spinner v-if="isFetchingResults" />
                    <SearchFilters :part-results :distributors :manufacturers />
                </div>
                <SearchTabsContent
                    id="search-distributors"
                    trigger-id="search-tab-by-distributor"
                    :selected="currentView == 'by-distributor'">
                    <SearchDistributors v-if="searchContext?.Results" :search-results="searchContext.Results" />
                </SearchTabsContent>
                <SearchTabsContent
                    id="search-parts"
                    trigger-id="search-tab-by-part"
                    :selected="currentView != 'by-distributor'">
                    <SearchParts v-if="searchContext?.Results" :search-results="searchContext.Results" />
                </SearchTabsContent>
                <Alert v-if="showNoResultsFiltered" variant="danger" class="my-8 max-w-xl">
                    <AlertDescription>{{ $t("Search.NoResultsFiltered") }}</AlertDescription>
                </Alert>
            </div>
            <SearchAds
                :key="searchContext?.SearchId"
                class="hidden md:block w-40 shrink-0"
                :google-ad-id="searchContext?.Results.GoogleAdId" />
        </div>
        <ClientOnly>
            <SearchReturnTop :header="header" />
        </ClientOnly>
    </section>
</template>
<script setup lang="ts">
import type { ShallowRef } from "vue";

const { t } = useI18n();
const route = useRoute();
const globalConfig = useStateGlobalConfig();
const filterValues = useStateSearchFilters();
const localePath = useLocalePath();
const searchUrl = useSearchUrl();
const { currentView, defaultView } = useSearchView();
const api = useApi();
const { toast } = useToast();

const isFetchingResults = ref(false);
const header = useTemplateRef("header");
const visibleDistributorIds = shallowRef<Set<number>>(new Set());
const visibleManufacturerIds = shallowRef<Set<number>>(new Set());
const visiblePartKeys = shallowRef<Set<string>>(new Set());
const visibleSortOrders = shallowRef<Set<string>>(new Set());

// search URL is either /search/{mfr}/{query} or /search/{query}
const path = Array.isArray(route.params.path) ? route.params.path : [route.params.path];
const queryText = path[path.length - 1];
const manufacturerPath = path.length > 1 ? path[0] : undefined;
const isBot = route.query.bot == "true";

// if query is empty redirect home
if (queryText.trim().length < 1) navigateTo(localePath("/"));

// reset filter values on each new search
filterValues.value = { inStock: false, manufacturerIds: [], distributorIds: [] };

const { data: searchContext } = await useFetchLocaleApi<SearchContextTransformed | InvalidSearchResult>("api/search", {
    query: { query: queryText, manufacturer: manufacturerPath, bot: isBot },
    deep: false,
    onResponse({ response }) {
        response._data = transformSearchContext(response._data);
    },
});

if (!searchContext.value?.IsValidSearch && searchContext.value?.Type == "Redirect") {
    await navigateTo(searchContext.value.URL);
}

useSetCanonicalUrl(searchUrl(queryText, manufacturerPath));
globalConfig.value.SearchText = queryText;

const title = computed(() =>
    searchContext.value?.IsValidSearch && searchContext.value.Manufacturer
        ? t("Search.SearchTitleManufacturerPartNumber", [searchContext.value.Manufacturer, queryText])
        : t("Search.SearchTitlePartNumber", [queryText])
);
const description = computed(() => t("Search.MetaDescriptionFormat", [queryText]));

useSeoMeta({ title, ogTitle: title, description, ogDescription: description });

const partResults = computed(() => searchContext.value?.Results?.PartResults ?? []);

const partDetailResults = computed(() => searchContext.value?.Results?.PartDetailResults ?? []);

const distributors = computed(() => searchContext.value?.Results?.Distributors ?? []);

const manufacturerMap = computed(() => searchContext.value?.Results?.ManufacturerMap ?? new Map());

const manufacturers = computed<PartResultManufacturer[]>(() => {
    return Array.from(manufacturerMap.value.values()).sort((manufacturerA, manufacturerB) =>
        manufacturerA.Name.localeCompare(manufacturerB.Name)
    );
});

const isSearchInvalid = computed(() => {
    if (!searchContext.value) return false;
    if (!searchContext.value.IsValidSearch) return true;
    if (!searchContext.value.Results.IsComplete) return false;
    if (partDetailResults.value.length < 1) return true;
    return false;
});

const invalidSearchResult = computed(() =>
    searchContext.value && !searchContext.value.IsValidSearch ? searchContext.value : null
);

const isFiltering = computed(
    () =>
        filterValues.value.inStock ||
        filterValues.value.distributorIds.length > 0 ||
        filterValues.value.manufacturerIds.length > 0
);

const showNoResultsFiltered = computed(() => isFiltering.value && visiblePartKeys.value.size < 1);

/** Update a Set shallow ref with a new value only if it actually changed.
 * Since these refs are injected to potentially hundreds of child components,
 * this strategy avoids needlessly recomputing too much too often.
 */
function maybeUpdateSet<T>(setRef: ShallowRef<Set<T>>, newSet: Set<T>) {
    if (!areSetsEqual<T>(setRef.value, newSet)) setRef.value = newSet;
}

function updateVisibleIds() {
    const visible = calculateVisibleIds(partResults.value, filterValues.value);
    maybeUpdateSet(visibleDistributorIds, visible.distributorIds);
    maybeUpdateSet(visibleManufacturerIds, visible.manufacturerIds);
    maybeUpdateSet(visiblePartKeys, visible.partKeys);
    maybeUpdateSet(visibleSortOrders, visible.sortOrders);
}

function showHomeRegionWarning() {
    if (searchContext.value?.IsValidSearch && searchContext.value.ShowHomeRegionWarning) {
        const canViewAmerica = searchContext.value.CanViewAmericasPricing;
        const homeRegionName = searchContext.value.HomeRegionName;
        const message = [
            t("Search.HomeRegionName", [homeRegionName]),
            t(canViewAmerica ? "Search.HomeRegionChina" : "Search.HomeRegionOther"),
        ].join(" ");
        toast({ description: message, variant: "warning" });
    }
}

onMounted(() => {
    // we have to wait until mount event because localStorage is not available on the server
    currentView.value = defaultView.value;

    if (searchContext.value?.IsValidSearch) {
        isFetchingResults.value = true;
        showHomeRegionWarning();
        api<SearchResults>("api/search/results", { method: "GET", query: { id: searchContext.value.SearchId } })
            .then((searchResults) => {
                if (!searchContext.value || !searchResults.IsComplete) return;

                // reassign because shallow ref
                searchContext.value = {
                    ...searchContext.value,
                    Results: transformSearchResults(searchResults),
                } as SearchContextTransformed;
            })
            .finally(() => {
                isFetchingResults.value = false;
            });
    }
});

updateVisibleIds();
watch(partResults, updateVisibleIds);
watch(filterValues, updateVisibleIds, { deep: true });

provide(SearchIdsInjectKey, {
    distributorIds: visibleDistributorIds,
    manufacturerIds: visibleManufacturerIds,
    partKeys: visiblePartKeys,
    sortOrders: visibleSortOrders,
    clickHash: computed(() => (searchContext.value?.IsValidSearch ? searchContext.value.ClickHash : "")),
});
</script>
