<template>
    <Popover v-model:open="isOpen">
        <PopoverTrigger as-child>
            <Button class="group" variant="primary">
                <span>{{ $t("Search.Availability") }}</span>
                <i class="fass fa-chevron-right group-data-[state=open]:rotate-90 transition-transform"></i>
                <Badge variant="primary" @click.stop="onBadgeClick()">
                    <span> {{ badgeText }}</span>
                    <Icon name="xmark" />
                </Badge>
            </Button>
        </PopoverTrigger>
        <PopoverContent align="start">
            <Command v-model="filterValues.inStock">
                <CommandList>
                    <CategoryFilterItem :value="true" @click="isOpen = false">
                        {{ $t("Global.InStock") }}
                    </CategoryFilterItem>
                    <CategoryFilterItem :value="false" @click="isOpen = false">
                        {{ $t("Search.Any") }}
                    </CategoryFilterItem>
                </CommandList>
            </Command>
        </PopoverContent>
    </Popover>
</template>
<script setup lang="ts">
const { t } = useI18n();
const isOpen = ref(false);
const filterValues = useStateSearchFilters();

function onBadgeClick() {
    filterValues.value.inStock = !filterValues.value.inStock;
    isOpen.value = false;
}

const badgeText = computed(() => t(filterValues.value.inStock ? "Global.InStock" : "Search.Any"));
</script>
