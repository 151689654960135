<template>
    <div class="grid grid-cols-1 md:grid-flow-col auto-cols-fr rounded-md" role="tablist" aria-label="Result view tabs">
        <slot />
    </div>
</template>
<script setup lang="ts">
const tab = defineModel<string | number>();

function setTab(value: string | number) {
    tab.value = value;
}

provide(SearchInjectKey, { tab: readonly(tab), setTab });
</script>
