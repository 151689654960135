<template>
    <div
        v-if="props.result?.Type == 'Block'"
        class="p-4 text-lg max-w-screen-sm bg-danger-100 text-danger-900 rounded"
        data-testid="search-block">
        {{ $t("ErrorPage.Blocked") }}
    </div>
    <div v-else class="mt-4" data-testid="search-invalid">
        <div class="font-bold text-danger" v-html="$t('Search.MessageNoMatchesHeader')"></div>
        <div id="search-instructions" v-html="$t('Search.MessageNoMatchesInstructions')"></div>
    </div>
</template>
<script setup lang="ts">
const props = defineProps<{ result: InvalidSearchResult | null }>();
</script>
<style>
#search-instructions ul {
    @apply list-disc ml-4 max-w-screen-sm;
}
#search-instructions .Bold {
    font-weight: bold;
}
</style>
