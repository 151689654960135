<template>
    <div class="py-2 my-2" :class="{ hidden: isPartHidden }" data-testid="search-part">
        <SearchPartHeader :result="props.part">
            <template #actions><slot name="actions"></slot></template>
            <template #chart><slot name="chart"></slot></template>
        </SearchPartHeader>
        <SearchPartResults v-if="props.part.PartResults" :parts="props.part.PartResults" show-distributor show-rohs />
    </div>
</template>
<script setup lang="ts">
import type { PartDetailResultGroup } from "~/utils/search";

const searchIds = inject(SearchIdsInjectKey);

const isPartHidden = computed(() => !searchIds?.partKeys.value.has(props.part.Key));

const props = defineProps<{ part: PartDetailResultGroup }>();
</script>
