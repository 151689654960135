<template>
    <div :class="{ hidden: isDistributorHidden }">
        <div class="flex mb-2 justify-between">
            <h3>{{ props.distributor.DisplayName }}</h3>
            <div>({{ matchesLabel }})</div>
        </div>
        <SearchPartResults :parts="props.distributor.PartResults" show-manufacturer />
        <div v-if="props.distributor.ViewAllUrl" v-bind="footerProps" class="text-xs text-right font-bold mt-1">
            <a :href="props.distributor.ViewAllUrl" target="_blank" rel="noopener" data-ga="s:AllResults">
                {{ props.distributor.ViewAllText }}
            </a>
        </div>
    </div>
</template>
<script setup lang="ts">
const { t } = useI18n();
const props = defineProps<{ distributor: DistributorResultGroup }>();
const searchIds = inject(SearchIdsInjectKey);

// this whole component can be hidden by search filters
const isDistributorHidden = computed(() => !searchIds?.distributorIds.value.has(props.distributor.Id));
const matches = computed(() => props.distributor.TotalResultCount);
const matchesLabel = computed(() => (matches.value > 1 ? t("Search.Matches", [matches.value]) : t("Search.Match")));
const footerProps = computed(() => ({
    "data-dist": props.distributor.Name + " [" + props.distributor.Id + "]",
    "data-hash": searchIds?.clickHash.value,
}));
</script>
