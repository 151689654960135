// Search result utilities

export type PartDetailResultGroup = PartDetailResultTransformed & {
    PartResults: PartResult[];
};

export type DistributorResultGroup = PartResultDistributorTransformed & { PartResults: PartResult[] };

const emptyManufacturer: PartResultManufacturer = { Id: 0, Name: "", DisplayName: "", VirtualPath: "" };

export function transformSearchContext(searchContext: SearchContext): SearchContextTransformed | SearchContext {
    const { Results: searchResults, ...other } = searchContext;

    return searchContext.IsValidSearch ? { ...other, Results: transformSearchResults(searchResults) } : searchContext;
}

function transformDistributor(distributorResult: DistributorSearchResult): PartResultDistributorTransformed {
    const { Distributor, TotalResultCount, ExactResultCount, ExactMatchCount, ViewAllUrl, ViewAllText } =
        distributorResult;
    return {
        ...Distributor,
        TotalResultCount,
        ExactResultCount,
        ExactMatchCount,
        ViewAllUrl,
        ViewAllText,
    };
}

type OtherKeyRecord = { Key: string; ManufacturerId: number; ManufacturerName: string };

/**
 * Do search result data cleanup and sorting
 * TODO: this function's output should essentially be what the API outputs
 * 1. Create temporary part detail records from part records
 * 2. Map non-canonical manufacturers parts to canonical manufacturer parts
 * 3. Output manufacturers and distributors since we have them anyway
 */
export function transformSearchResults(searchResults: SearchResults): SearchResultsTransformed {
    const otherKeyRecordMap = new Map<string, OtherKeyRecord>();
    const partDetailMap = new Map<string, PartDetailResult>();
    const manufacturerMap = new Map<number, PartResultManufacturer>();
    const distributors: PartResultDistributorTransformed[] = [];
    const partResults: PartResult[] = [];

    // loop through part detail results to build manufacturer ID and part key mappings
    searchResults.PartDetailResults.forEach((partDetail) => {
        if (partDetail.OtherManufacturerIds.length > 0) {
            const record: OtherKeyRecord = {
                Key: partDetail.Key,
                ManufacturerId: partDetail.ManufacturerId,
                ManufacturerName: partDetail.ManufacturerName,
            };

            partDetail.OtherManufacturerIds.forEach((otherId) => {
                const otherKey = `${otherId}-${partDetail.PartNumberScrubbedNonMeaningful}`;
                otherKeyRecordMap.set(otherKey, record);
            });
        }
    });

    // get all manufacturers and distributors
    searchResults.DistributorResults.forEach((distributorResult) => {
        distributors.push(transformDistributor(distributorResult));
        distributorResult.Results.forEach((partResult) => {
            const mfrId = partResult.Manufacturer.Id;
            if (!manufacturerMap.has(mfrId)) manufacturerMap.set(mfrId, partResult.Manufacturer);
        });
    });

    // make part detail map with fixed part keys and manufacturers
    // part detail results will be empty on first search
    searchResults.PartDetailResults.forEach((partDetail) => {
        const record = otherKeyRecordMap.get(partDetail.Key);
        if (record) {
            partDetailMap.set(record.Key, { ...partDetail, ...record });
        } else {
            partDetailMap.set(partDetail.Key, partDetail);
        }
    });

    searchResults.DistributorResults.forEach((distributorResult) => {
        distributorResult.Results.forEach((partResult) => {
            // handle if this specific part key was mapped to another manufacturer
            const record: OtherKeyRecord = otherKeyRecordMap.get(partResult.Key) ?? {
                Key: partResult.Key,
                ManufacturerId: partResult.Manufacturer.Id,
                ManufacturerName: partResult.Manufacturer.Name,
            };

            // make temporary part detail results if needed
            if (!partDetailMap.has(record.Key)) {
                const tempPartDetail: PartDetailResult = {
                    ...partResult,
                    ...record,
                    PartNumberScrubbedNonMeaningful: partResult.ScrubbedPartNumberNonMeaningful,
                    PartId: 0,
                    OtherManufacturerIds: [],
                    IsSelfHostedImage: false,
                    IsAffectedByTariff: false,
                    CategoryHierarchy: [],
                    LifecycleRisk: -1,
                    SupplyChainRisk: -1,
                };
                partDetailMap.set(record.Key, tempPartDetail);
            }

            partResults.push({
                ...partResult,
                Key: record.Key,
                Manufacturer: manufacturerMap.get(record.ManufacturerId) ?? partResult.Manufacturer,
            });
        });
    });

    distributors.sort((distributorA, distributorB) => distributorA.Name.localeCompare(distributorB.Name));

    // try to add full manufacturer object to main part detail result
    let mainResult: PartDetailResultTransformed | undefined = undefined;
    if (searchResults.MainPartDetailResult) {
        const mfrId = searchResults.MainPartDetailResult.ManufacturerId;
        mainResult = { ...searchResults.MainPartDetailResult, Manufacturer: manufacturerMap.get(mfrId) };
    }

    return {
        IsComplete: searchResults.IsComplete,
        GoogleAdId: searchResults.GoogleAdId,
        ManufacturerMap: manufacturerMap,
        Distributors: distributors,
        PartDetailResults: Array.from(partDetailMap.values()),
        MainPartDetailResult: mainResult,
        PartResults: partResults,
    };
}

// group search results by product (part) and sort output
export function groupResultsByPart(
    searchText: string,
    searchResults: SearchResultsTransformed
): PartDetailResultGroup[] {
    const partResultsByKey = new Map<string, PartResult[]>();
    // totals used for sort
    const partIsMatch: Record<string, number> = {};
    const distributorsWithStock: Record<string, number> = {};
    const totalStock: Record<string, number> = {};

    searchResults.PartResults.forEach((partResult) => {
        const partResults = partResultsByKey.get(partResult.Key);

        if (!partResults) {
            partResultsByKey.set(partResult.Key, [partResult]);
            // Prioritize exact part number and search text equality, then IsExactMatch property
            partIsMatch[partResult.Key] = partResult.PartNumber == searchText ? 2 : partResult.IsExactMatch ? 1 : 0;
            distributorsWithStock[partResult.Key] = 0;
            totalStock[partResult.Key] = 0;
        } else {
            partResults.push(partResult);
        }

        if (partResult.QuantityOnHand) {
            totalStock[partResult.Key] += partResult.QuantityOnHand;
            distributorsWithStock[partResult.Key] += 1;
        }
    });

    const output: PartDetailResultGroup[] = searchResults.PartDetailResults.map((partDetail) => {
        return {
            ...partDetail,
            // manufacturer object makes the part detail satisfy the CommonPart type for notifications and charts
            Manufacturer: searchResults.ManufacturerMap.get(partDetail.ManufacturerId) ?? emptyManufacturer,
            PartResults: partResultsByKey.get(partDetail.Key) ?? [],
        };
    });

    // Sorts by exact match first, then # of distributors (desc), then total stock (desc)
    // Sorting (relevance) logic belongs in the API layer, but this is a temporary workaround.
    output.sort((partA, partB) => {
        let result = partIsMatch[partB.Key] - partIsMatch[partA.Key];
        if (result == 0) result = distributorsWithStock[partB.Key] - distributorsWithStock[partA.Key];
        if (result == 0) result = totalStock[partB.Key] - totalStock[partA.Key];

        return result;
    });

    return output;
}

// group distributors and then sort by sponsors before non-sponsors, then random (client only)
export function groupResultsByDistributor(
    searchResults: SearchResultsTransformed,
    sort: boolean
): DistributorResultGroup[] {
    const output: DistributorResultGroup[] = searchResults.Distributors.map((distributor) => {
        return {
            ...distributor,
            PartResults: searchResults.PartResults.filter((partResult) => partResult.Distributor.Id == distributor.Id),
        };
    });

    if (!sort) return output;

    // TODO: move distributor sort to API to avoid hydration mismatch
    const randomOrder = new Map<number, number>(output.map((result) => [result.Id, Math.random()]));

    output.sort((distA, distB) => {
        const [isSponsorA, isSponsorB] = [distA.IsSponsor ? 1 : 0, distB.IsSponsor ? 1 : 0];
        let result = isSponsorB - isSponsorA;
        if (result == 0) result = (randomOrder.get(distA.Id) ?? 0) - (randomOrder.get(distB.Id) ?? 0);
        return result;
    });

    return output;
}
