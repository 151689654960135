<template>
    <SearchPartHeader :result="props.result" :class="cn('mt-4', props.class)">
        <template #chart>
            <div v-if="chartData" class="hidden lg:block w-1/4">
                <PartChartPreview
                    :part="chartPart"
                    :part-key="props.result.Key"
                    :initial-chart-data="chartData"
                    preview-height="100"
                    @click="partChartDialog?.openPartDetail(props.result, $event)" />
            </div>
        </template>
        <template #actions>
            <PartAddToBom :part="props.result" size="sm" />
            <NotificationButton
                size="sm"
                :disabled="isNotificationLoading"
                :has-notification
                @click="openNotification()" />
        </template>
    </SearchPartHeader>
    <NotificationDialog ref="notificationDialog" @update="notification = $event" />
    <PartChartDialog ref="partChartDialog" />
</template>
<script setup lang="ts">
import type { HTMLAttributes } from "vue";

const api = useApi();
const isAuthenticated = useStateIsAuthenticated();
const props = defineProps<{ result: PartDetailResult; class?: HTMLAttributes["class"] }>();
const notificationDialog = useTemplateRef<NotificationDialog>("notificationDialog");
const partChartDialog = useTemplateRef("partChartDialog");
const chartData = ref<PartChartData | null>(null);
const isNotificationLoading = ref(false);
const notification = ref<PartNotification | null>(null);
const hasNotification = ref(false);

const chartPart = computed(() => ({
    ...props.result,
    Manufacturer: { Id: props.result?.ManufacturerId ?? 0, Name: props.result?.ManufacturerName ?? "" },
}));

onMounted(() => {
    const { ManufacturerId, PartNumber } = props.result;
    const fetchOptions = {
        body: {
            Parts: [{ ManufacturerId, PartNumber }],
        },
    };

    api<PartChartData[]>("api/part-chart", { method: "POST", ...fetchOptions }).then((data) => {
        if (Array.isArray(data)) chartData.value = data[0];
    });

    if (isAuthenticated.value) {
        isNotificationLoading.value = true;
        api<PartNotificationsResponse>("api/notification-data", { method: "POST", ...fetchOptions })
            .then((data) => {
                if (data) {
                    // the endpoint returns notifications we didn't ask for (!) so make sure the Part ID matches
                    notification.value = data.PartNotifications.find((pn) => pn.PartId === props.result.PartId) ?? null;
                    hasNotification.value = notification.value !== null;
                } else {
                    notification.value = null;
                    hasNotification.value = false;
                }
            })
            .finally(() => {
                isNotificationLoading.value = false;
            });
    }
});

function openNotification() {
    notificationDialog.value?.openDialog(chartPart.value, notification.value);
}
</script>
