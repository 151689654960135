<template>
    <Popover v-model:open="isOpen">
        <PopoverTrigger as-child>
            <Button class="group min-w-48 justify-between">
                <span>{{ $t("CompanyPanel.TabMatchingDistributors") }}</span>
                <span>({{ props.distributors.length }})</span>
                <Icon name="chevron-right" class="group-data-[state=open]:rotate-90 transition-transform" />
            </Button>
        </PopoverTrigger>
        <PopoverContent
            class="w-auto"
            hide-when-detached
            align="end"
            data-testid="search-distributor-links"
            @close-auto-focus.prevent>
            <div class="grid p-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-1.5">
                <div v-for="dist in sponsors" :key="dist.Id" class="font-bold text-base">
                    <a :href="'#distributor-' + dist.Id" @click="isOpen = false">{{ dist.Name }}</a>
                </div>
                <div v-for="dist in others" :key="dist.Id">
                    <a :href="'#distributor-' + dist.Id" @click="isOpen = false">{{ dist.Name }}</a>
                </div>
            </div>
        </PopoverContent>
    </Popover>
</template>
<script setup lang="ts">
const props = defineProps<{ distributors: PartResultDistributor[] }>();
const isOpen = ref(false);

const sponsors = computed(() => props.distributors.filter((d) => d.IsSponsor));
const others = computed(() => props.distributors.filter((d) => !d.IsSponsor));
</script>
