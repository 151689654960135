<template>
    <CommandItem :value="true" class="group">
        <span class="border rounded px-1 mr-2 group-data-[state=checked]:bg-primary">
            <Icon name="check" class="invisible text-white group-data-[state=checked]:visible" />
        </span>
        <span class="grow">{{ props.company.DisplayName }}</span>
        <span class="min-w-14 shrink-0 text-right">{{ resultsFormatted }}</span>
        <span class="min-w-14 shrink-0 text-right">{{ exactFormatted }}</span>
    </CommandItem>
</template>
<script setup lang="ts">
const props = defineProps<{ company: SearchFilterCompany }>();

const resultsFormatted = computed(() => props.company.Total.toLocaleString());
const exactFormatted = computed(() => props.company.Exact.toLocaleString());
</script>
