<template>
    <aside>
        <div id="tp-ad-slot-top"></div>
        <div id="tp-ad-slot-bottom"></div>
    </aside>
</template>
<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../node_modules/@types/google-publisher-tag/index.d.ts" />
const DEFAULT_PATH = "/4250/ECIA/globalauthorized";

type AdUnit = { path: string; id: string };

const props = defineProps<{ googleAdId?: string }>();

function showAds(units: AdUnit[]) {
    // The link to gpt.js is in app.vue
    if (window.googletag && typeof window.googletag.destroySlots == "function") {
        window.googletag.destroySlots();
    }
    defineSlots(units);
    displayAds(units);
}

function defineSlots(units: AdUnit[]) {
    window.googletag = window.googletag || { cmd: [] };

    googletag.cmd.push(function () {
        units.forEach((unit) => {
            googletag.defineSlot(unit.path, [160, 600], unit.id)?.addService(googletag.pubads());
        });
        googletag.pubads().setForceSafeFrame(true);
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
    });
}

function displayAds(units: AdUnit[]) {
    units.forEach((unit) => {
        googletag.cmd.push(function () {
            googletag.display(unit.id);
        });
    });
}

onMounted(() => {
    const units: AdUnit[] = [
        { path: props.googleAdId ?? DEFAULT_PATH, id: "tp-ad-slot-top" },
        { path: DEFAULT_PATH, id: "tp-ad-slot-bottom" },
    ];
    if (window.innerWidth >= 992) {
        showAds(units);
    }
});
</script>
