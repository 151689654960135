// Search filter utilities

type CombinationCount = { Total: number; Exact: number };
type CombinationMaps = Map<number, Map<number, CombinationCount>>;
type CombinationSets = Map<number, Set<number>>;

/** CompanyCombinations pre-aggregates manufacturer or distributor totals to update search filters quickly.
 * The two instances of the this class are created in the function calculateCompanyCombinations.
 * For each instance of this class, one company type is the "inner", and the other is the "outer",
 * essentially "manufacturers by distributor" or "distributors by manufacturer".
 * Once created, this filters valid combinations and calculates counts for each filter combination.
 * It answers the question: given the current filters, which companies have results, and how many?
 */
export class CompanyCombinations {
    // the key is the first ID and each value of is an inner map
    // where the key of is the second ID, and the value is the count of the combinations
    private _comboMaps: CombinationMaps = new Map();

    // the key is the first ID and each value is a set of inner IDs, calculated once by freeze()
    // used to shortcut the sum when there's no filter
    private _comboSets: CombinationSets = new Map();

    // this is the sum of counts by first (outer) ID, shortcut when not filtering
    private _counts: Map<number, CombinationCount> = new Map();

    add(outerId: number, innerId: number, isExact: boolean) {
        let comboMap = this._comboMaps.get(outerId);
        if (comboMap) {
            const innerCount = comboMap.get(innerId) ?? { Total: 0, Exact: 0 };
            innerCount.Total += 1;
            if (isExact) innerCount.Exact += 1;
            comboMap.set(innerId, innerCount);
        } else {
            comboMap = new Map();
            comboMap.set(innerId, { Total: 1, Exact: isExact ? 1 : 0 });
            this._comboMaps.set(outerId, comboMap);
        }

        const outerCount = this._counts.get(outerId) ?? { Total: 0, Exact: 0 };
        outerCount.Total += 1;
        if (isExact) outerCount.Exact += 1;
        this._counts.set(outerId, outerCount);
    }

    // after all results have been analyzed, finalize the sets using the keys from the maps
    freeze() {
        this._comboMaps.forEach((map, id) => {
            this._comboSets.set(id, new Set(map.keys()));
        });
    }

    /**
     * Determine if a company is related to other companies, used before sumCounts to filter the list
     * @param outerId The single outer ID (as defined during data load)
     * @param innerIds inner IDs to match against
     * @returns true if outer ID is valid and overlaps with inner IDs
     */
    has(outerId: number, innerIds: Set<number>) {
        const set = this._comboSets.get(outerId);
        if (!set) return false;
        return !innerIds.isDisjointFrom(set);
    }

    /**
     * Return a total and exact count for a single ID, optionally filtered by IDs.
    This is the main workhorse of this class. For example, if this is distributor-manufacturers,
    this function calculates the total/exact counts for one distributor ID, optionally
    filtered by a set of manufacturer IDs.
     * @param outerId The single outer ID (as defined during data load)
     * @param innerIds inner IDs to match against (optional)
     * @returns total of all intersections
     */
    sumCounts(outerId: number, innerIds?: Set<number>) {
        // If there's no filter applied, then return the full total (shortcut for most common case)
        if (!innerIds) {
            return this._counts.get(outerId) ?? { Total: 0, Exact: 0 };
        }

        const map = this._comboMaps.get(outerId);
        const sum: CombinationCount = { Total: 0, Exact: 0 };
        if (!map) return sum;

        innerIds.forEach((id) => {
            const count = map.get(id);
            if (count) {
                sum.Total += count.Total;
                sum.Exact = +count.Exact;
            }
        });
        return sum;
    }

    length() {
        return this._comboMaps.size;
    }
}

/**
 * Calculate all possible manufacturer/distributor combinations and their counts
 * Used to pare down available filter options to only valid combinations and provide accurate totals.
 *
 * @param results unfiltered distributor results output from the API
 * @param inStockOnly if true, limit combinations to in-stock parts only
 * @returns A CompanyCombinations class instance for distributors and another for manufacturers
 */
export function calculateCompanyCombinations(results: PartResult[], inStockOnly: boolean) {
    const distributorManufacturers = new CompanyCombinations();
    const manufacturerDistributors = new CompanyCombinations();

    results.forEach((partResult) => {
        if (partResult.IsDistributorDuplicate) return; // filter out duplicates
        if (inStockOnly && partResult.QuantityOnHand < 1) return; // filter out parts out of stock if needed
        const dId = partResult.Distributor.Id;
        const mId = partResult.Manufacturer.Id;
        const isExact = partResult.IsExactMatch;
        distributorManufacturers.add(dId, mId, isExact);
        manufacturerDistributors.add(mId, dId, isExact);
    });

    distributorManufacturers.freeze();
    manufacturerDistributors.freeze();
    return { distributorManufacturers, manufacturerDistributors };
}

/**
 * Calculate which distributors, manufacturers, and products in a given search result should be visible.
 * We don't want to filter the actual search result objects directly because that is much less efficient
 * than simply toggling each item's visibility with CSS (display: none).
 * This is re-run every time a search filter option is changed, so it needs to be as fast as possible.
 * We use key and sort order to identify parts and part results because they are the only unique IDs currently available.
 *
 * @param results unfiltered distributor results output from the API
 * @param filterValues current filter options
 * @returns sets of distributor IDs, manufacturer IDs, part keys, and result sort orders that should be visible
 */
export function calculateVisibleIds(results: PartResult[], filterValues: SearchFilters) {
    const visiblePartResults: { partKey: string; sortOrder: string; distId: number; mfrId: number }[] = [];

    const selectedDistIds = new Set(filterValues.distributorIds);
    const selectedMfrIds = new Set(filterValues.manufacturerIds);
    const isDistVisible = selectedDistIds.size > 0 ? (distId: number) => selectedDistIds.has(distId) : () => true;
    const isMfrVisible = selectedMfrIds.size > 0 ? (mfrId: number) => selectedMfrIds.has(mfrId) : () => true;
    const isStockOK = filterValues.inStock ? (stock: number) => stock > 0 : () => true;

    results.forEach((partResult) => {
        const distId = partResult.Distributor.Id;
        const mfrId = partResult.Manufacturer.Id;
        if (isStockOK(partResult.QuantityOnHand) && isDistVisible(distId) && isMfrVisible(mfrId)) {
            visiblePartResults.push({
                partKey: partResult.Key,
                sortOrder: partResult.SortOrder,
                distId,
                mfrId,
            });
        }
    });

    // make unique visible key/ID sets
    const distributorIds = new Set<number>();
    const manufacturerIds = new Set<number>();
    const partKeys = new Set<string>();
    const sortOrders = new Set<string>();

    visiblePartResults.forEach(({ partKey, sortOrder, distId, mfrId }) => {
        distributorIds.add(distId);
        manufacturerIds.add(mfrId);
        partKeys.add(partKey);
        sortOrders.add(sortOrder);
    });

    return { distributorIds, manufacturerIds, partKeys, sortOrders };
}
