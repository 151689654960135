<template>
    <Button
        v-if="!headerIsVisible"
        size="sm"
        as="a"
        href="#"
        class="fixed bg-white top-0 md:top-20 right-2 z-20 print:hidden">
        {{ $t("Search.MessageReturnToTop") }}
    </Button>
</template>
<script setup lang="ts">
import { useElementVisibility, type MaybeComputedElementRef, type MaybeElement } from "@vueuse/core";

const props = defineProps<{ header: MaybeComputedElementRef<MaybeElement> }>();
const headerIsVisible = useElementVisibility(props.header);
</script>
