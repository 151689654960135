<template>
    <div class="p-2 flex flex-col sm:flex-row gap-4 border border-b-0" data-testid="search-part-header">
        <div class="flex-none">
            <NuxtLink v-if="props.result.Url" :to="props.result.Url" :aria-label="props.result.PartNumber">
                <PartImage size="md" :image-url="props.result.ImageUrl" :alt="props.result.PartNumber" />
            </NuxtLink>
            <PartImage v-else size="md" :image-url="props.result.ImageUrl" :alt="props.result.PartNumber" />
        </div>
        <div class="lg:flex-none lg:w-2/5 flex flex-col gap-2">
            <h2 class="my-0">
                <div class="text-lg" data-testid="search-part-header-mfg">{{ props.result.Manufacturer?.Name }}</div>
                <div class="text-3xl" data-testid="search-part-header-part-num">
                    <NuxtLink v-if="props.result.Url" :to="props.result.Url">{{ props.result.PartNumber }}</NuxtLink>
                    <template v-else>{{ props.result.PartNumber }}</template>
                </div>
            </h2>
            <div class="flex flex-wrap gap-2 min-h-6">
                <PartLifecycleRisk :risk="props.result.LifecycleRisk" />
                <PartSupplyChainRisk :risk="props.result.SupplyChainRisk" />
                <PartTariff v-if="props.result.IsAffectedByTariff" />
            </div>
            <div class="flex flex-wrap gap-2 items-center">
                <slot name="actions"></slot>
                <PartModelsLink
                    v-if="props.result.Manufacturer && props.result.Url"
                    :href="modelsUrl"
                    :manufacturer="props.result.Manufacturer.VirtualPath"
                    :part-number="props.result.PartNumber" />
            </div>
        </div>
        <div class="text-right flex-1 hidden md:block">
            <div v-if="props.result.CategoryHierarchy" class="leading-6 mb-2" data-testid="category-hierarchy">
                <template v-for="(cat, index) in props.result.CategoryHierarchy" :key="cat.Url">
                    <i v-if="index > 0" class="fass fa-chevron-right px-1"></i>
                    <NuxtLink :to="cat.Url" class="">{{ cat.Name }}</NuxtLink>
                </template>
            </div>
            <p v-once>{{ props.result.Description }}</p>
        </div>
        <slot name="chart"></slot>
    </div>
</template>
<script setup lang="ts">
const props = defineProps<{ result: PartDetailResultTransformed }>();

const modelsUrl = computed(() => props.result.Url + "/models");
</script>
